<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          Type
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-select
            flat
            outlined
            dense
            :items="types"
            v-model="type"
            @change="onChangeType"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          <v-radio-group row v-model="params.date">
            <v-radio label="INV#" :value="false"></v-radio>
            <v-radio label="Pay Date" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            return-object
            v-model="invoice"
            :items="listInvoice"
            @change="onChangeInvoice"
            v-if="!params.date"
            item-text="invoiceNumber"
          >
          </v-autocomplete>
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            v-if="params.date"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.payDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.payDate" @input="menuDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="1">
          <v-btn color="success" @click="search"> <v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.totalInvoice="{ item }">
          {{ formatPrice(item.totalInvoice) }}
        </template>
        <template v-slot:item.totalInvoicePaid="{ item }">
          {{ formatPrice(item.totalInvoicePaid) }}
        </template>
        <template v-slot:item.totalInvoicePaidIDR="{ item }">
          {{ formatPrice(item.totalInvoicePaidIDR) }}
        </template>
        <template v-slot:item.fullPayment="{ item }">
          <v-checkbox v-model="item.fullPayment" disabled></v-checkbox>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    types: ["customer", "vendor"],
    type: "customer",
    menuDate: false,
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Inv #",
        value: "invoiceNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Company",
        value: "customerName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Inv Curr",
        value: "currency",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Inv Amt",
        value: "totalInvoice",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Pay Date",
        value: "payDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Pay For",
        value: "payFor",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Pay Curr",
        value: "payCurr",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "Amt Pay",
        value: "totalInvoicePaidIDR",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 9,
        text: "Pay (In Inv Curr)",
        value: "totalInvoicePaid",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "Trans #",
        value: "formNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "Full Paid",
        value: "fullPayment",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    items: [],
    listInvoice: [],
    invoice: {},
    params: {
      type: "",
      invoiceId: null,
      date: true,
      payDate: moment().format("yyyy-MM-DD"),
    },
  }),

  methods: {
    search() {
      if (this.type === "customer") {
        this.getResultPaymentCustomer();
      } else {
        this.getResultPaymentVendor();
      }
    },
    getResultPaymentCustomer() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("salePayment/queryPayment", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getResultPaymentVendor() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("purchasePayment/queryPayment", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    onChangeType(val) {
      (this.params.invoiceId = null), (this.params.type = "");
      if (val === "customer") {
        this.getInvoiceCustomer();
      } else {
        this.getInvoiceVendor();
      }
    },
    async getInvoiceCustomer() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/getInvoices", { proforma: false })
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInvoiceVendor() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseInvoice/getInvoices")
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangeInvoice(val) {
      this.params.invoiceId = val.id;
      this.params.type = val.type;
    },
  },
  mounted() {
    this.getInvoiceCustomer();
  },
};
</script>

<style></style>
